//Curently built with JQuery, will rebuild

function responsiveNav(){
	$(document).ready(function() {
	$('[data-toggle="navigation"]').click(function(e) {
		e.preventDefault();
		$('body').toggleClass('nav-expanded');
	});

		// Add span for when there's a subnav on mobile
		if ($(window).width() < 769) {
			// Set this ID to whatever your nav element has
			var $subnav = $("#nav li.sub-nav")
			var more = $("<span class='more'>+</span>");

			if ($subnav.hasClass("sub-nav")) {
				// Add the more span
				$subnav.prepend(more);
			}
		}
	});
	
}

export default responsiveNav;