import Glide from '@glidejs/glide';
import responsiveNav from './components/nav';
import Expandset from './lib/Expandset';

// expandsets
let expandsets = document.querySelectorAll('[data-expandset]');
for(let expandset of expandsets) {
  new Expandset(expandset);
}


//Responsive Nav
responsiveNav();

if(document.querySelector('.glide_logo_slider')) {
  var logoSlider = new Glide('.glide_logo_slider', {
    type: 'carousel',
    autoplay: 3500,
    perView: 5,
    breakpoints: {
      900: {
        perView: 1
      }
    }
  });

  logoSlider.mount();
}

if(document.querySelector('.glide_timeline_slider')) {
var timelineSlider = new Glide('.glide_timeline_slider', {
  type: 'carousel',
  autoplay: 5000,
  perView: 2,
  breakpoints: {
    1030: {
      perView: 1
    }
  }
});

timelineSlider.mount();
}

if(document.querySelector('.glide_img_slider')) {
var imgSlider = new Glide('.glide_img_slider', {
	type: 'carousel',
  autoplay: 3500,
  perView: 1
});

imgSlider.mount();
}

if(document.querySelector('.glide_projects_slider')) {
var projectSlider = new Glide('.glide_projects_slider', {
  type: 'carousel',
  autoplay: 3500,
  perView: 4,
	// breakpoints: {
  //   800: {
  //     perView: 2
  //   }
  // }
});

projectSlider.mount();
}

let $ = window.jQuery;
$(document).ready(function () {
  $(".modal-open").click(function () {
    var modalId = $(this).attr("data-modal");
    $(".modal").removeClass("active");
    $(".overlay").addClass("active");
    $("#"+modalId).addClass("active");
    $('html').addClass('no-scroll');
  });

  $(".modal-close").click(function () {
    $(".overlay").removeClass("active");
    $(".modal").removeClass("active");
    $('html').removeClass('no-scroll');
  });
});